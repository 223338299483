import Link from "next/link"
import { DOELogo } from "./doeLogo"
export function PARSLogo() {
  return (
    <Link href="/">
      <div className="flex flex-row">
        <DOELogo className="ml-2 max-[420px]:w-[130px]" />
        <div className="ml-2 max-[420px]:mt-1 mt-3 max-h-10 w-[3px] bg-aquaBlue" />
        <h1 className="text-nav-text-light ml-4 mt-1 min-[420px]:mt-2 scroll-m-20 max-[420px]:text-3xl text-5xl font-bold tracking-tight dark:text-nav-text-dark ">
          PARS
        </h1>
      </div>
    </Link>
  )
}
